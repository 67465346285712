var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guorenlist"},[_c('div',{staticClass:"main mainWidth"},[_c('el-row',{staticClass:"crumbs",attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('el-breadcrumb',{attrs:{"separator":">"}},[_c('el-breadcrumb-item',{attrs:{"to":"/"}},[_vm._v("社科视频首页")]),_c('el-breadcrumb-item',{attrs:{"to":"/xueren"}},[_vm._v("学人")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.headtion))])],1),_c('ListHeadline',{attrs:{"title":_vm.headtion}}),_c('div',{staticClass:"page_k"},[_c('div',{staticClass:"soooooo"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 16 : 0}},_vm._l((_vm.list),function(a){return _c('el-col',{key:a.id,attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('router-link',{attrs:{"target":"_blank","to":{
                    path: '/xq',
                    query: {
                      qing: a.id,
                      end: _vm.headtion,
                    },
                  }}},[_c('div',{staticClass:"page_top"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 23 : 0}},[_c('el-col',{attrs:{"xs":24,"sm":10,"md":10,"lg":10,"xl":10}},[_c('div',{staticClass:"page_top_img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(a.pub_cover),expression:"a.pub_cover"}],attrs:{"alt":""}})])]),_c('el-col',{attrs:{"xs":24,"sm":14,"md":14,"lg":14,"xl":14}},[_c('div',{staticClass:"shu_zi"},[_c('p',{staticClass:"left_zi"},[_vm._v(_vm._s(a.SYS_TOPIC))]),_c('div',{staticClass:"zhong_zi"},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$AwayTell( a.DESCRIPTION, 0, _vm.$store.state.isSmallPc ? 46 : 88 ))+" ")]),_c('span',{staticClass:"particulars"},[_vm._v("[详细]")])]),_c('p',{staticClass:"right_zi"},[_vm._v(" "+_vm._s(_vm.$ModuleDate(a.RECORD_TIME))+" ")])])])],1)],1)])],1)}),1)],1)]),_c('Paging',{attrs:{"value":_vm.value},on:{"transfer":_vm.getData}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }