<template>
  <div class="guorenlist">
    <div class="main mainWidth">
      <!-- 首页>社科视频>报刊视界 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item to="/">社科视频首页</el-breadcrumb-item>
            <el-breadcrumb-item to="/xueren">学人</el-breadcrumb-item>
            <el-breadcrumb-item>{{ headtion }}</el-breadcrumb-item>
          </el-breadcrumb>

          <ListHeadline :title="headtion" />

          <!-- 列表 -->
          <div class="page_k">
            <!-- 单列表 -->
            <div class="soooooo">
              <el-row :gutter="$store.state.isEquipment == 'pc' ? 16 : 0">
                <el-col
                  :xs="24"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  v-for="a in list"
                  :key="a.id"
                >
                  <router-link
                    target="_blank"
                    :to="{
                      path: '/xq',
                      query: {
                        qing: a.id,
                        end: headtion,
                      },
                    }"
                  >
                    <div class="page_top">
                      <el-row
                        :gutter="$store.state.isEquipment == 'pc' ? 23 : 0"
                      >
                        <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                          <div class="page_top_img">
                            <img v-lazy="a.pub_cover" alt="" />
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                          <div class="shu_zi">
                            <p class="left_zi">{{ a.SYS_TOPIC }}</p>
                            <div class="zhong_zi">
                              <span class="text">
                                {{
                                  $AwayTell(
                                    a.DESCRIPTION,
                                    0,
                                    $store.state.isSmallPc ? 46 : 88
                                  )
                                }}
                              </span>
                              <span class="particulars">[详细]</span>
                            </div>
                            <p class="right_zi">
                              {{ $ModuleDate(a.RECORD_TIME) }}
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </div>
          <Paging :value="value" @transfer="getData" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  
<script>
import NavType from "@/components/NavType.vue";
import ListHeadline from "@/components/ListHeadline.vue";
import Paging from "@/components/Paging.vue";

export default {
  components: {
    NavType,
    ListHeadline,
    Paging,
  },
  props: {},
  data() {
    //这里存放数据
    return {
      headtion: "",
      list: [],
      value: {
        path: this.$route.query.id + "",
        amount: "10",
      },
    };
  },
  watch: {},
  //方法集合
  methods: {
    getTitle(val) {
      this.path = val;
      switch (this.$route.query.id) {
        case "86":
          this.headtion = "共和国学人";
          break;
        case "87":
          this.headtion = "学术百家";
          break;
        case "72":
          this.headtion = "大家印象";
          break;
        default:
          break;
      }
    },

    getData(msg) {
      this.list = msg;
    },
  },
  created() {
    this.getTitle(this.$route.query.id);
  },
};
</script>
<style lang='scss' scoped>
.guorenlist {
  background: #f6f6f6;
  padding-bottom: 2.5rem;

  .kong {
    width: 100%;
    width: 3.8125rem;
  }

  .main {
    height: 100%;
    margin: 0 auto;

    .maysk {
      width: 100%;
      font-size: 1.875rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
      margin-bottom: 1.25rem;
    }

    // .line {
    //   .line_l {
    //     width: 4.5rem;
    //     height: .3125rem;
    //     background: linear-gradient(237deg, transparent 5px, #2181b0 0) top right;
    //   }

    //   .line_r {
    //     width: 82.5rem;
    //     height: 1px;
    //     background: #668594;
    //     margin-left: .1875rem;
    //   }
    // }

    .page_k {
      .page_top {
        background: #ffffff;
        margin-top: 1rem;
        padding: 1.25rem;
        position: relative;

        .page_top_img {
          img {
            aspect-ratio: 4/3;
            width: 100%;
            height: 194px;
          }
        }

        .shu_zi {
          color: #212121;
          height: 100%;

          .left_zi {
            font-size: 1.25rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            // color: #212121;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .zhong_zi {
            margin-top: 0.75rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #707070;
            line-height: 1.875rem;
            text-align: justify;

            .particulars {
              color: #287498;
              font-size: 1rem;
              cursor: pointer;
              margin-left: 0.3125rem;
            }
          }

          .right_zi {
            position: absolute;
            bottom: 0%;
            color: #c29460;
          }
        }
      }
    }
  }
}
@media screen and(max-width:1440px) {
  .guorenlist .main .page_k .page_top .page_top_img img {
    height: 134px;
  }
}
</style>